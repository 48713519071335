import { DTO } from "@/Models/dto";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import http from "./http";

const endPoint = "Notification";
export const useNotification = () =>
  useMutation({
    mutationKey: ["Notification"],
    mutationFn: (body: DTO.NotificationBody) =>
      http.post<DTO.NotificationBody, DTO.MutateResponse<DTO.NotificationBody>>(`${endPoint}/AddNewNotification`, body).then(res => res.data),
  });

export const useMarkAsReadNotificationById = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["MarkAsReadNotificationById"],
    mutationFn: (body: { id: number }) =>
      http.put<void, DTO.MutateResponse<null>>(`${endPoint}/MarkAsReadNotificationById?id=${body.id}`).then(res => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["Notification"] });
    },
  });
};
export const useGetNotificationsByUserId = (userId?: number) =>
  useQuery({
    queryKey: ["Notification"],
    queryFn: () => http.get<DTO.NotificationBody[]>(`${endPoint}/GetNotificationsByUserId?userId=${userId}`).then(res => res.data),
    enabled: !!userId,
  });
