/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallbackComponent: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: unknown) {
    // Update state so the next render will show the fallback UI.

    return { hasError: true };
  }

  componentDidCatch(_error: unknown, _info: unknown) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    // logErrorToMyService(error, info.componentStack);
    console.log("first");
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallbackComponent;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
