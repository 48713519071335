/* eslint-disable @typescript-eslint/no-explicit-any */
import useLanguage from "@/Stores/useLanguage";
import { clsx, type ClassValue } from "clsx";
import { AES, enc } from "crypto-js";
import take from "lodash/take";
import { extendTailwindMerge } from "tailwind-merge";

export const twMerge = extendTailwindMerge({});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const langRegex = {
  enRegex: /^[a-zA-Z- ]+$/,
  arRegex: /^[\u0621-\u064A- ]+$/,
  bothArAndEnRegexonlyLetters: /^[a-zA-Z-\u0621-\u064A ]+$/,
  bothArAndEnRegexonlyLettersAndNumbers: /^[a-zA-Z0-9-\u0621-\u064A ]+$/,
  enletterAndNumbersRegex: /^[a-zA-Z0-9]*$/,
  mobileNumber: /^(5)(\d){8}$/,
  TelPhone: /^(0096611)(\d){7}$/,
  nationalId: /^1(\d){9}$/,
  nationalOrResidentId: /^[12](\d){9}$/,
  nonSaudianNationalId: /^2(\d){9}$/,
  tags: /<[^>]*>?/gm,
  specialCharcter: /[!@#$%^&*()_+\-'~<>/\\?|÷×]/,
};

export const handleArabicNumbers = (e: React.ChangeEvent<HTMLInputElement>, watch: any) => {
  const reg = /^[0-9\u0660-\u0669]+$/;
  if (!reg.test(e.target.value) && e.target.value !== "") {
    e.target.value = watch;
  }

  e.target.value = e.target.value
    .replace(/[٠١٢٣٤٥٦٧٨٩]/g, d => {
      return String(d.charCodeAt(0) - 1632);
    })
    .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, d => {
      return String(d.charCodeAt(0) - 1776);
    });
  return e;
};

export const formatter = (dir: string) => {
  return new Intl.DateTimeFormat(dir === "rtl" ? "ar" : "en", { day: "2-digit", month: "short", year: "numeric" });
};
export const formatterTime = (dir: string) => {
  return new Intl.DateTimeFormat(dir === "ar" ? "ar" : "en", { timeStyle: "short" });
};

export const useSwitchData = () => {
  const { lang } = useLanguage(state => state);
  return (arValue: string = "", enValue: string = "") => {
    return String(lang).toLocaleLowerCase() === "ar" ? arValue : enValue;
  };
};

export function debounce<T extends (...args: any[]) => void>(func: T, wait: number): T {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  return function (this: any, ...args: Parameters<T>) {
    const later = () => {
      timeout = null;
      func.apply(this, args);
    };

    if (timeout !== null) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(later, wait);
  } as T;
}

export const Paginate = <T>(items: T[] | undefined, pageNumber: number, pageSize: number) => {
  if (!items) return;
  const startIndex = (pageNumber - 1) * pageSize;
  const sliceItem = items.slice(startIndex);
  return take(sliceItem, pageSize);
};

export const encrypt = (data: string): string => {
  const encrypted = AES.encrypt(data, import.meta.env.VITE_SECRET).toString();
  return enc.Base64.stringify(enc.Utf8.parse(encrypted));
};

export const decrypt = (cipherText: string): string => {
  const bytes = AES.decrypt(enc.Base64.parse(cipherText).toString(enc.Utf8), import.meta.env.VITE_SECRET);
  return bytes.toString(enc.Utf8);
};

export const fromDate = (year: number) => new Date(Date.UTC(year, 0, 1)).toISOString();
export const toDate = (year: number) => new Date(Date.UTC(year, 11, 31)).toISOString();

export function getMonthList(locales?: string | string[], format: "long" | "short" = "long"): string[] {
  const year = new Date().getFullYear(); // 2020
  const monthList = [...Array(12).keys()]; // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
  const formatter = new Intl.DateTimeFormat(locales, {
    month: format,
  });

  const getMonthName = (monthIndex: number) => formatter.format(new Date(year, monthIndex));

  return monthList.map(getMonthName);
}

export const getWeekSuffix = (weekNumber: number) => {
  const lastDigit = weekNumber % 10;
  const lastTwoDigits = weekNumber % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return "th";
  }

  switch (lastDigit) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export function formateDownloadFile(base64: string) {
  let base64Modified = base64;
  if (base64.startsWith("JVB")) {
    base64Modified = "data:application/pdf;base64," + base64;
  } else if (base64.startsWith("/9j")) {
    base64Modified = "data:image/jpg;base64," + base64;
  } else if (base64.startsWith("iVB")) {
    base64Modified = "data:image/png;base64," + base64;
  }
  return base64Modified;
}

export const downloadFile = (base64: string, filename: string) => {
  const link = document.createElement("a");
  link.href = formateDownloadFile(base64);
  link.download = filename ?? "file.pdf"; // or any other name you want to give to the downloaded file
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
