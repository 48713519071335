import ErrorImage from "@/Assets/images/error.svg";

import { strings } from "@/Locales";
import { Button } from "@/Shared/Components/ui/button";
import { clsx } from "clsx";
import { ScrollRestoration, useNavigate, useRouteError } from "react-router-dom";

const ErrorPage = ({ isErrorPage }: { isErrorPage?: boolean }) => {
  const naviagte = useNavigate();
  const error = useRouteError() as string;

  console.log(error);

  if (isErrorPage)
    return (
      <div
        className={clsx({
          "flex-grow": true,
        })}>
        <div className="flex flex-col items-center justify-center gap-16 lg:mt-28">
          <h1 className="title-01">{strings.Shared.ServerError}</h1>
          <img src={ErrorImage} alt="ErrorImage" width={400} />
          <Button onClick={() => naviagte("/")}>{strings.Shared.BackToHome}</Button>
        </div>
      </div>
    );

  return (
    <div className={"relative flex min-h-screen flex-col overflow-hidden"}>
      <ScrollRestoration />

      <div
        className={clsx({
          "flex-grow": true,
        })}>
        <div className="flex flex-col items-center justify-center gap-16 lg:mt-28">
          <h1>{strings.Shared.ServerError}</h1>
          <img src={ErrorImage} alt="ErrorImage" width={400} />
          <Button variant={"outline"} onClick={() => naviagte("/")}>{strings.Shared.BackToHome}</Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
