import { strings } from "@/Locales";
import { DTO } from "@/Models/dto";
import { useGetNotificationsByUserId, useMarkAsReadNotificationById } from "@/Services/Notification";
import { ShouldRender } from "@/Shared/Components";
import { Badge } from "@/Shared/Components/ui/badge";
import { Button } from "@/Shared/Components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/Shared/Components/ui/dropdown-menu";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "@/Shared/Components/ui/sheet";
import { useAuth, useDateFormat } from "@/Shared/Hooks";
import useLanguage from "@/Stores/useLanguage";
import clsx from "clsx";
import { Bell, BellIcon } from "lucide-react";
import { Fragment, useMemo } from "react";
const Notifications = () => {
  const { dir } = useLanguage();
  const { user } = useAuth();
  const notif = useGetNotificationsByUserId(user?.id);

  const markAsReadNotificationById = useMarkAsReadNotificationById();
  const onIsRead = (id?: number) => {
    if (!id) return;

    try {
      markAsReadNotificationById.mutateAsync({ id });
    } catch (error) {
      console.log(error);
    }
  };

  const inNotReead = useMemo(() => {
    return notif?.data?.filter(n => !n.isRead).length;
  }, [notif?.data]);

  return (
    <DropdownMenu dir={dir}>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="sm" className="overflow-hidden relative">
          <Bell className="h-5 w-5" />
          <ShouldRender shouldRender={!!inNotReead}>
            <div className="w-2 h-2 rounded-full bg-red-500 absolute top-1 right-2"></div>
          </ShouldRender>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <ShouldRender shouldRender={!notif.isLoading}>
          {!!notif?.data &&
            notif?.data?.map((ntf, indx) => (
              <Fragment key={ntf.id}>
                <DropdownMenuItem asChild>
                  <Button
                    onClick={() => onIsRead(ntf.id)}
                    variant={"ghost"}
                    className={clsx(
                      "w-full h-auto cursor-pointer text-start items-start rounded-md p-3 transition-all hover:bg-accent hover:text-accent-foreground focus-visible:ring-0 focus-visible:ring-offset-0",
                      {
                        "bg-muted": !ntf.isRead,
                      }
                    )}>
                    <div className="flex items-start space-x-4">
                      <BellIcon className="mt-px h-5 w-5" />
                      <div className="space-y-1">
                        <p className="text-sm font-semibold leading-none">{strings.Shared[ntf.title]}</p>
                        <p className="line-clamp-2 text-xs text-muted-foreground">
                          {strings.formatString(strings.messages[ntf.title as keyof typeof strings.messages], ntf.content)}
                        </p>
                      </div>
                    </div>
                  </Button>
                </DropdownMenuItem>

                <ShouldRender shouldRender={indx !== notif?.data?.length - 1}>
                  <DropdownMenuSeparator />
                </ShouldRender>
              </Fragment>
            ))}

          <ShouldRender shouldRender={!!notif?.data?.length}>
            <DropdownMenuSeparator />
            <DropdownMenuItem className="text-center justify-center" asChild>
              <NotificationsAll notifications={notif.data} onIsRead={onIsRead} inNotReead={inNotReead} />
            </DropdownMenuItem>
          </ShouldRender>
          <ShouldRender shouldRender={!notif?.data?.length}>
            <DropdownMenuLabel className="p-4">{strings.messages.NoNotification}</DropdownMenuLabel>
          </ShouldRender>
        </ShouldRender>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default Notifications;

const NotificationsAll = ({
  notifications,
  onIsRead,
  inNotReead,
}: {
  notifications?: DTO.NotificationBody[];
  onIsRead: (id?: number) => void;
  inNotReead?: number;
}) => {
  const formater = useDateFormat();
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant={"outline"} className="w-full">
          {strings.Shared.ShowAll}
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle className="space-x-2">
            <span>{strings.Shared.Notifications}</span>
            <ShouldRender shouldRender={!!inNotReead}>
              <Badge variant={"outline"}>{inNotReead} </Badge>
            </ShouldRender>
          </SheetTitle>
        </SheetHeader>
        <div className="flex flex-col gap-2 p-4 pt-0">
          {notifications?.map(notif => (
            <button
              key={notif.id}
              onClick={() => onIsRead(notif?.id)}
              className={clsx("flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent", {
                "bg-muted": !notif.isRead,
              })}>
              <div className="flex w-full flex-col gap-1">
                <div className="flex items-center">
                  <div className="flex items-center gap-2">
                    <div className="font-semibold">{strings.Shared[notif?.title]}</div>
                  </div>
                  <div className="ml-auto text-xs text-foreground">{formater(notif?.createdDate)}</div>
                </div>
              </div>
              <div className="line-clamp-2 text-xs text-muted-foreground">
                {strings.formatString(strings.messages[notif?.title as keyof typeof strings.messages], notif?.content)}
              </div>
            </button>
          ))}
        </div>
      </SheetContent>
    </Sheet>
  );
};
