/* eslint-disable react-hooks/exhaustive-deps */
import { ErrorBoundary } from "@/Shared/Components";
import { Toaster } from "@/Shared/Components/ui/toaster";
import { Outlet, ScrollRestoration } from "react-router-dom";
import ErrorPage from "../Errors/Error";
import Aside from "./Aside";
import Header from "./Header";

const Layouts = () => {
  return (
    <ErrorBoundary fallbackComponent={<ErrorPage />}>
      <div className="relative flex min-h-screen flex-col bg-background">
        <div className="flex min-h-screen w-full flex-col bg-muted/40   text-card-foreground">
          <Aside />
          <div className="flex flex-col sm:gap-4 sm:py-4 sm:ps-14">
            <Header />
            <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
              <ScrollRestoration />
              <Outlet />
              <Toaster />
            </main>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Layouts;
