import useLanguage from "@/Stores/useLanguage";

export const useDateFormat = () => {
  const { lang } = useLanguage();

  return (date?: string | Date) => {
    if (!date) return;
    return new Date(date).toLocaleString(lang === "ar" ? "ar-AE" : "en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
};
