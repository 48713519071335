export const en = {
  messages: {
    exceedlimitedField: "{0} field size is {1} characters",
    required: "{0} is required",
    LoginFailed: "The mobile or password you entered is incorrect",
    ForgotPasswordFailed: "The mobile you entered is incorrect",
    InvalidEmail: "Invalid Email Address",
    InvalidMobile: "Invalid Mobile Value",
    NumberOnly: "A number is required",
    SendSuccess: "The request has been sent Successfuly",
    EditSuccess: "The request has been Edit Successfuly",
    SendError: "An error occurred while sending the request",
    Confirm: "Are you sure you want to delete?",
    ConfirmVaraint: "Are you sure you want to {0}?",
    ConfirmVaraintDesc: "This will permanently {0} {1}",
    InvalidStartDate: "Start date must be before end date",
    NoResultFound: "No results found",
    NoResultsFoundDescription: "The data and its details will appear as they appear in this section.",
    Boxdesc: "A list of all the Boxes including their name, start date, and amount.",
    Memberdesc: "A list of all the Members including their name, email and mobile number.",
    FormDesc: "Make changes here. Click save when you're done",
    MatchPassword: "Passwords must match!",
    MatchDownPayemnt: "DownPayment must match!",
    PrevPayemntMultipication: "Previous amount must be a multiple of {0}",
    PaymentProfileDesc: "To view payments, please complete the payments profile",
    PaymentsLimitedExceeded: "You can only choose 12 payments",
    NoWithdrawalsDesc: "There are no withdrawals, you can make a withdrawal through",
    UndoConfirmTitle: "Are you absolutely sure?",
    UndoConfirmDescription: "This action cannot be undone. This will permanently Undo payment status.",
    //ConfirmDescription: "This action cannot be undone. This will permanently Undo payment status.",
    UpdateAccountDescription: "Update your account. Reset your password.",
    UpdateProfileDescription: "Update your profile. Set your preferred name,email and mobile number.",
    BackLogin: "Back to login page",
    ForgotPssword: "User {0} has requested a password reset.",
    AccessDenied: "you don't have permission to access this page",
    NoNotification: "You  don't any notifications yet.",
    ReminderNotif: `Dear Subscriber,
    We would like to remind you of a payment due on {0} for the account of the {1} fund, with an amount of {2} JD.`,
  },

  Shared: {
    Barnamjy: "Barnamjy",
    Dashboard: "Dashboard",
    SiteLanguageText: "العربية",
    soon: "soon",
    ShowAll: "Show All",
    Notifications: "Notifications",
    MyAccount: "My Account",
    ResetPassword: "Reset Password",
    Settings: "Settings",
    NationalId: "National ID",
    ResidentID: "Resident ID",
    Welcome: "Welcome, ",
    Login: "Login",
    Logout: "Log out",
    UserName: "User Name",
    Password: "Password",
    ForgotPssword: "Forgot Pssword",
    ConfirmPassword: "Confirm Password",
    FrogotPassword: "Forgot your password?",
    InformationPanel: "Information Panel",
    AccountSettings: "Account Settings",
    Chat: "Chat Now",
    TechnicalSupport: "Technical Support",
    FullName: "Full Name",
    PersonalInformation: "Personal Information",
    TicketInformation: "Ticket Information",
    TicketDetails: "Ticket Details",
    Title: "Title",
    Description: "Description",
    Enter: "Enter",
    AddSupportingFiles: "Attachments",
    Send: "Submit",
    Save: "Save",
    Submit: "Submit Request",
    Cancel: "Cancel",
    Main: "Home",
    Details: "Details",
    Summary: "Summary",
    Select: "Select",
    NotRobot: "Please verify you are not a robot",
    Search: "Search",
    Number: "Number",
    Date: "Date",
    hijriCalendar: "Hijri Calendar",
    male: "Male",
    female: "Female",
    submit: "Submit",
    next: "Next",
    previous: "Previous",
    morePages: "More pages",
    Total: "Total",
    TotalPaidAmount: "Total Paid Amount",
    TotalDueAmount: "Total Due Amount",
    TotalWithdrawnAmount: "Total Withdrawn Amount",
    TotalBalance: "Total Balance",
    totalScheduled: "Total Scheduled",
    Withdraw: "Withdraw",
    Withdrawals: "Withdrawals",
    YearTitle: "Year",
    MonthTitle: "Month",
    PaginationDesc: "Showing {0} to {1} of {2} results",
    LinesPerPage: "Rows per page",
    Actions: "Actions",
    ViewDetails: "View Details",
    Language: "Language",
    Specify: "Specify",
    ServerError: "Sorry, something went wrong",
    BackToHome: "Back to Main Page",
    Error: "Error",
    Success: "Success",
    Email: "Email Address",
    Mobile: "Mobile",
    MobileNumber: "Mobile Number",
    Admin: "Admin",
    Edit: "Edit",
    Delete: "Delete",
    Users: "Users",
    User: "User",
    Member: "Member",
    MemberName: "Member Name",
    Members: "Members",
    Add: "Add",
    Boxes: "Boxes",
    MyBoxes: "My Boxes",
    Box: "Box",
    BoxName: "Box Name",
    Continue: "Continue",
    Enable: "Enable",
    Disable: "Disable",
    Disabled: "Disabled",
    Name: "Name",
    Amount: "Amount",
    Payment: "Payment",
    PreviousBalance: "Previous Balance",
    DownPayment: "Down Payment",
    PaymentAmountType: "Payment Amount Type",
    Fixed: "Fixed",
    Varaible: "Varaible",
    StartDate: "Start Date",
    EndDate: "End Date",
    Period: "Period",
    Status: "Status",
    Active: "Active",
    InActive: "InActive",
    SavingCircle: "Saving Circle",
    New: "New",
    NewF: "New {0}",
    Create: "Create",
    Monthly: "Montly",
    Weekly: "Weekly",
    Week: "Week",
    datePlaceHolder: "Pick a date",
    Manage: "Manage",
    JOD: "JOD",
    Participants: "Participants",
    AddParticipants: "Add Participants",
    Steps: "Step {0} of {1}",
    ChooseParticipants: "Choose Participants",
    ChoosedminMember: "Choose Admin",
    Payments: "Payments",
    PaymentMethod: "PAYMENT METHOD",
    PaymentSchedule: "PAYMENT SCHEDULE",
    PaymentTotal: "Payment Total",
    DueTotal: "Due Total",
    NextPayment: "Next Payment",
    PaymentDate: "Payment Date",
    PaymentStatus: "Payment Status",
    ManageMontlyPaymentsDesc: "Manage Monthly Payments for each Member",
    ManageWeeklyPaymentsDesc: "Manage Weekly Payments for each Member",
    BoxMembers: "Box Members",
    BoxMembersDesc: "Manage box members",
    SelectMemeberPayment: "Select Member to Show Payments Details",
    SelectMember: "Select Member",
    Paid: "Paid",
    Due: "Due",
    Scheduled: "Scheduled",
    Exempted: "Exempted",
    UpdateAccount: "Update account",
    UpdateProfile: "Update profile",
    Profile: "Profile",
    Account: "Account",
    SettingsDesc: "Manage your account settings.",
    Reports: "Reports",
    BoxTransaction: "Box Transactions",
    BoxReport: "Box Report",
    MemberReport: "Member Report",
    WithdrawalReport: "Withdrawals Report",
    ReportsDesc: "Browse reports according to specific parameters and criteria",
    Selected: "Selected",
    FromDate: "From Date",
    ToDate: "To Date",
    SearchCreteria: "Search Creteria",
    TransactionDate: "Transaction Date",
    PaymentsProfile: "Payments Profile",
    PaymentsProfileFor: "Payments Profile for {0}",
    joiningDate: "Joining Date",
    NewWithdrawal: "New withdrawal",
    WithdrawalDate: "Withdrawal Date",
    Note: "Note",
    Asc: "Asc",
    Desc: "Desc",
    Export: "Export",
    GeneralTransaction: "General Transactions",
    Type : "Type",
  },
  Footer: {
    TermsOfUse: "Terms Of Use",
    PrivacyPolicy: "Privacy Policy ",
    InformationProtection: " Information Protection ",
    Colors: "Colors (On/Off)",
    VoiceCommands: "Voice Commands",
    CopyRights: "All rights reserved ",
  },
  Authentication: {
    LoginDesc: "Enter your Mobile below to login to your account",
    ForgetPasswordDesc: "Enter your Mobile below to send Notification for admin to your reset your password",
  },
};
